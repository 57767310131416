import React, { Component } from 'react'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import clientConfig from '../../client-config'
import { GatsbyImage } from 'gatsby-plugin-image'
import { isMobile } from 'react-device-detect'

class InlineHoverImageText extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMouseTooltipVisible: false,
      offsetY: 0,
    }
    this.onMouseEnterText = this.onMouseEnterText.bind(this)
    this.onMouseLeaveText = this.onMouseLeaveText.bind(this)
  }

  onMouseEnterText(e) {
    // if (window.innerHeight % e.pageX < 100) {
    //   this.setState({
    //     offsetY: -200,
    //   })
    // }
    if (!isMobile) {
      this.setState({
        isMouseTooltipVisible: true,
      })
    }
  }

  onMouseLeaveText() {
    this.setState({
      isMouseTooltipVisible: false,
    })
  }

  render() {
    const { image, text } = this.props
    const imageData =
      image &&
      getGatsbyImageData(
        image.asset.id,
        {
          maxWidth: 500,
          fit: 'fillmax',
          placeholder: 'blurred',
        },
        clientConfig.sanity
      )
    return (
      <>
        {this.state.isMouseTooltipVisible && (
          <MouseTooltip
            className="mouse-tooltip no-border-top"
            offsetX={15}
            offsetY={this.state.offsetY}
          >
            <GatsbyImage className="inline-hover-image" image={imageData} alt={text} />
          </MouseTooltip>
        )}
        <span onMouseEnter={this.onMouseEnterText} onMouseLeave={this.onMouseLeaveText}>
          {text}
        </span>
      </>
    )
  }
}

export default InlineHoverImageText
