import React, { useState, useRef, useEffect } from 'react'
import { buildSrcSetStr, buildPlaceholderUrl } from '../lib/helpers'
import 'lazysizes'

const ImageLoader = props => {
  let {
    className,
    imageData,
    widths,
    alt,
    style,
    index,
    indexDelay,
    imageId,
    preventDrag,
    noBlur,
    scaleOffset,
    noBg,
  } = props
  if (imageId) {
    imageData = {
      asset: { _id: imageId },
    }
  }
  const imageRef = useRef()
  let objectPosition = {}
  if (imageData && imageData.hotspot) {
    const { x, y } = imageData.hotspot
    objectPosition = { objectPosition: `${(x * 100).toFixed(2)}% ${(y * 100).toFixed(2)}%` }
  }
  const placeholderUrl = imageData && imageData.asset ? buildPlaceholderUrl(imageData, 20) : ''
  const dataSrcset = imageData && imageData.asset ? buildSrcSetStr(imageData, widths) : ''

  return (
    <>
      {imageData?.asset ? (
        <div
          className="image-loader--wrapper"
          style={{
            ...(objectPosition.objectPosition
              ? { backgroundPosition: objectPosition.objectPosition }
              : {}),
            backgroundImage: noBg ? `` : `url("${placeholderUrl}")`,
          }}
        >
          <img
            ref={imageRef}
            data-sizes="auto"
            src={placeholderUrl}
            data-srcset={dataSrcset}
            className={`${className ? className : ''} ${
              preventDrag ? 'prevent-drag' : ''
            } image-loader lazyload ${!noBlur ? 'blur-up' : ''} ${
              scaleOffset ? 'scale-offset' : ''
            }`}
            data-delay={index}
            alt={alt}
            style={{
              ...style,
              ...objectPosition,
              ...(indexDelay ? { transitionDelay: `${index * 50}ms` } : {}),
            }}
            draggable={!preventDrag}
          />
        </div>
      ) : (
        <div className="image-loader--placeholder"></div>
      )}
    </>
  )
}

export default ImageLoader
