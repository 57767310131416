import React from 'react'
import MainImage from './MainImage'
import ReactPlayer from 'react-player'
import InstagramEmbed from 'react-instagram-embed'
import InlineHoverImageText from './inline-hover-image-text'

const serializers = {
  marks: {
    link: ({ mark, children }) => {
      const { blank, button, href } = mark
      if (blank) {
        return (
          <a href={href} target="_blank" rel="noopener">
            {children}
          </a>
        )
      } else {
        if (button) {
          return (
            <a href={href} className="mob-mb-0_1 rounded-button rounded-button--inline">
              {children}
            </a>
          )
        } else {
          return <a href={href}>{children}</a>
        }
      }
    },
    hoverImage: ({ mark, children }) => {
      const { image } = mark
      return <InlineHoverImageText image={image} text={children} />
    },
    circleRight: ({ mark, children }) => {
      return <span className="circle-right">{children}</span>
    },
  },
  types: {
    mainImage: ({ node }) => <MainImage mainImage={node} />,
    videoEmbed: ({ node }) => <ReactPlayer url={node.url} controls />,
    instagram: ({ node }) => {
      if (!node.url) return null
      return <InstagramEmbed url={node.url} />
    },
  },
}

export default serializers
